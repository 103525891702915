import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 74,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "DM Sans, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#00140F",
      light: "#FFFFFF",
      dark: "#2B3332",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#00A179",
      light: "#f1f7f6",
      dark: "#1D1D1F",
    },
    tertiary: {
      main: "#004D39",
      light: "#027458",
      dark: "#008060",
    },
    text: {
      primary: "#F9F9F9",
      secondary: "#005741",
      content: "#000000",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: ` "DM Sans", sans-serif`,
  },
})

export default theme
